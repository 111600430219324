
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default {
  name: "RoutesSelect",
  props: {
    route_id: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:route_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const inputRoutes = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isRouteRequired = (value) => {
      if (props.required && !value) {
        return t("rroutec");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "route_name",
      isRouteRequired
    );

    const getRoute = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/command_operation/routes").then(({ data }) => {
        inputRoutes.value.list = data;
        inputRoutes.value.options = data;
      });
    };

    const selectRoute = (query) => {
      if (query !== "") {
        inputRoutes.value.loading = true;
        setTimeout(() => {
          inputRoutes.value.loading = false;
          inputRoutes.value.options = inputRoutes.value.list.filter((x) => {
            return x.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      }
    };

    watch(
      () => props.route_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      getRoute();
    });

    return {
      element_id,
      inputRoutes,
      errorMessage,
      selectRoute,
    };
  },
};
