
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default {
  name: "TablesSelect",
  props: {
    table_id: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:table_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const inputTables = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isTableRequired = (value) => {
      if (props.required && !value) {
        return t("rtable");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "table_name",
      isTableRequired
    );

    const getTable = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/command_operation/tables").then(({ data }) => {
        inputTables.value.list = data;
        inputTables.value.options = data;
      });
    };

    const selectTable = (query) => {
      if (query !== "") {
        inputTables.value.loading = true;
        setTimeout(() => {
          inputTables.value.loading = false;
          inputTables.value.options = inputTables.value.list.filter(
            (item: Record<string, any>) => {
              return item.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputTables.value.options = [];
      }
    };

    watch(
      () => props.table_id,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    onMounted(() => {
      getTable();
    });

    return {
      element_id,
      inputTables,
      errorMessage,
      selectTable,
    };
  },
};
